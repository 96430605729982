const careersPopup = () => {
    $('.open-career-modal[data-career]').on('click', function (e) {
        e.preventDefault();
        const career = $(this).attr('data-career');
        $('.career-modal[data-career="' + career + '"]').addClass('open');
        $('.career-modal-shadow[data-career="' + career + '"]').addClass('open');
        if (window.innerWidth <= 767) {
            $('#intercom-container').css({transform: 'translateY(80px)', transition: 'all .2s'});
        }
    });

    $('.career-modal-close').on('click', function (e) {
        const career = $(this).attr('data-career');
        $('.career-modal[data-career="' + career + '"]').removeClass('open');
        $('.career-modal-shadow[data-career="' + career + '"]').removeClass('open');
        $('#intercom-container').css({transform: 'translateY(0px)', transition: 'all .2s'});
    });

    $('.career-modal-shadow').on('click', function (e) {
        const career = $(this).attr('data-career');
        $('.career-modal[data-career="' + career + '"]').removeClass('open');
        $('.career-modal-shadow[data-career="' + career + '"]').removeClass('open');
        $('#intercom-container').css({transform: 'translateY(0px)', transition: 'all .2s'});
    });

    const open = window.location.href.split('open=')[1];
    if (open) {
        $(document).scrollTop($('.careers-section-offers').offset().top - 80);
        setTimeout(function () {
            $($('.career-box')[open]).find('.btn-box a').click();
        }, 400);
    }

};

export {
    careersPopup
}
