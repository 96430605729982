const init = () => {
    $('form.contact').on('submit', (e)=> {
        e.preventDefault();

        let content = {};
        $(this).serializeArray().forEach((field)=> {
            content[field.name] = field.value;
        });
        $.post('/contact.php', content)
            .then(()=> {
                $('form.contact').hide();
                $('.footer-component h4.title').text('Thanks!').addClass('done');
            });
    });
};

export {
    init
}