const playVideo = () => {
    $(window).resize(()=> {
        let video = $('video');
        if (window.innerWidth > 767) {
            setTimeout(()=> {
                video.addClass('visible');
                video[0].play();
            }, 300);
        } else {
            video.removeClass('visible');
            video[0].pause();
        }
    });
    $(window).resize();
};

export {
    playVideo
}