window.jQuery = window.$ = require('../node_modules/jquery/dist/jquery');
const bootstrap = require('../node_modules/bootstrap/dist/js/bootstrap.min');
const swiper = require('../node_modules/swiper/dist/js/swiper.jquery.min');
const header = require('./elements/header');
const footer = require('./elements/footer');
const sliderBoxes = require('./elements/sliderBoxes');
const mainSection = require('./elements/mainSection');
const columnBoxes = require('./elements/columnBoxes');

$(document).ready(()=> {
    header.init();
    footer.init();
    sliderBoxes.init();

    $('translate').click((e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
    });

    $('.scroll-footer').on('click', () => {
        $('html, body').animate({
            scrollTop: $('footer').offset().top
        }, 600, () => {
            $('footer input[name=name]').focus();
        });
    });

    switch (window.location.pathname.substr(3)) {
        case '/publishers':
            sliderBoxes.publishers();
            break;
        case '/careers':
            sliderBoxes.about();
            mainSection.playVideo();
            columnBoxes.careersPopup();
            break;
        case '/press':
            mainSection.playVideo();
            sliderBoxes.press();
            break;
        case '/':
        case '/about':
            mainSection.playVideo();
            break;
    }
});
