const init = () => {

};

const publishers = () => {
    let marketplaceSwiper = new Swiper('.marketplace-swiper', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true
    });

    let bxLinkSwiper = new Swiper('.bxLink-swiper', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true
    });
};

const about = () => {
    let reviewsSwiper = new Swiper('.reviews-swiper', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true
    });
};

const calculateSwiper = () => {
    if (window.innerWidth < 678) {
        return 1;
    }
    else if (window.innerWidth < 1024) {
        return 3;
    }
    else {
        return 4;
    }
};

const press = () => {
    let pressSwiper = new Swiper('.press-swiper', {
        slidesPerView: calculateSwiper(),
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        spaceBetween: 30
    });


    $(window).resize(()=> {
        pressSwiper.params.slidesPerView = calculateSwiper();
    });

};

export {
    init,
    publishers,
    about,
    press
}